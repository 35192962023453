.solidIcon {
  width: 80px;
  height: 80px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.locateAnAire,
.title {
  align-self: stretch;
  position: relative;
}
.title {
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
}
.locateAnAire {
  font-size: var(--a-body-small-size);
  letter-spacing: 0.4px;
  line-height: 16px;
}
.card {
  width: 256px;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) var(--px-sizes-xs);
  box-sizing: border-box;
  gap: var(--px-sizes-2xs);
  min-width: 256px;
  max-width: 448px;
  text-align: left;
  font-size: var(--a-label-large-size);
  color: var(--gray);
  font-family: var(--a-label-large);
}
@media screen and (max-width: 450px) {
  .card {
    max-width: 100%;
  }
}
