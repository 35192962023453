.Contact .lander {
  padding: 0px 16px 0px 16px;
  min-height: calc(100vh - 168px);
}

@media all and (min-width: 480px) {
  .Contact {

  }

  .Contact form {
    margin: 0 auto;
    max-width: 320px;
  }
}
@media all and (max-width: 479px) {
  .Contact {

  }

  .Contact form {
    margin: 0px auto;
    max-width: 280px;
  }
}
