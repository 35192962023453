.zermattCardTitle {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 40px;
  font-weight: 700;
  font-family: inherit;
}
.blankLine,
.toAchieveOur {
  margin: 0;
}
.easeForLandowners {
  font-weight: 600;
  font-family: var(--a-label-large);
}
.easeForLandownersWereCom1 {
  margin-bottom: 0;
}
.simplicity {
  font-weight: 600;
}
.ourPlatformIs,
.simplicity {
  font-family: var(--a-label-large);
}
.simplicityOurPlatformIsDe {
  margin-bottom: 0;
}
.community {
  font-weight: 600;
}
.community,
.weFosterA {
  font-family: var(--a-label-large);
}
.easeForLandownersWereCom {
  margin: 0;
  font-size: inherit;
  padding-left: var(--padding-5xl);
}
.zermattCardDescriptionContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--a-body-xlarge-size);
  letter-spacing: 0.5px;
  line-height: 26px;
  color: var(--color-slategray-100);
  font-family: var(--a-label-large);
}
.textWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.ctaChild {
  width: 302.7px;
  height: 0.7px;
  border-top: 0.7px solid var(--studio-lightmode-lightseparator-ecf0f6);
  box-sizing: border-box;
}
.button,
.ctaChild,
.icon {
  position: relative;
}
.button {
  flex: 1;
  letter-spacing: 0.01em;
  line-height: 22px;
  font-weight: 500;
}
.icon {
  height: 18px;
  width: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.locobutton {
  width: 114px;
  border-radius: var(--br-7xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-7xs) var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-7xs);
}
.locobutton:hover {
  background-color: var(--color-gainsboro-100);
}
.cta,
.infomation {
  flex-direction: column;
  align-items: flex-start;
}
.cta {
  align-self: stretch;
  display: none;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: center;
  font-size: var(--a-title-medium-size);
  color: var(--a-others-key-colors-primary);
  font-family: var(--a-label-large);
}
.infomation {
  flex: 1;
  display: flex;
  justify-content: center;
  gap: var(--gap-13xl);
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-card-mdmax);
}
.undrawNavigationReWxx41Icon {
  width: 300px;
  flex: 1;
  position: relative;
  max-height: 100%;
  overflow: hidden;
}
.illustrations {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  padding: 0 var(--padding-xl);
}
.contentwarp,
.graphicwrapper,
.illustrations {
  display: flex;
  align-items: center;
  justify-content: center;
}
.graphicwrapper {
  height: 149.8px;
  flex: 1;
  flex-direction: column;
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-card-mdmax);
}
.contentwarp {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px 19px;
  max-width: 100%;
}
.container,
.contentcomponentOurstrategy {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.container {
  width: 100%;
  align-items: flex-start;
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-content-contentmaxwidth);
}
.contentcomponentOurstrategy {
  align-self: stretch;
  box-shadow: 0 0 52.6px rgba(0, 0, 0, 0.03);
  background-color: var(--schemes-on-primary);
  overflow: hidden;
  align-items: center;
  padding: var(--padding-45xl) var(--padding-61xl);
  box-sizing: border-box;
  min-width: 360px;
  max-width: 100%;
  text-align: left;
  font-size: var(--a-headline-large-size);
  color: var(--color-darkslategray-100);
  font-family: var(--a-display-medium);
}
@media screen and (max-width: 1275px) {
  .container {
    max-width: var(--frame-sizes-content-contentmaxwidth);
  }
}
@media screen and (max-width: 750px) {
  .zermattCardTitle {
    font-size: var(--font-size-7xl);
    line-height: 32px;
  }
  .graphicwrapper,
  .infomation {
    gap: var(--gap-base);
    max-width: var(--frame-sizes-card-mdmax);
  }
  .container {
    gap: var(--gap-xl);
  }
  .contentcomponentOurstrategy {
    gap: var(--gap-4xl);
    padding: var(--padding-23xl) var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .zermattCardTitle {
    font-size: var(--font-size-lgi);
    line-height: 24px;
  }
}
