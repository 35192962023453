.hostbooking-details {
    /*max-width: 500px;
    margin: 0 auto;*/
    min-width: 400px;
    padding: 20px;
    background-color: #F8F9FA;
    font-family: 'Montserrat', sans-serif;
  }
  
  .hostbooking-details h2 {
    font-family: 'Comfortaa', cursive;
    color: #10385A;
    margin-bottom: 20px;
  }
  
  .details {
    padding: 0px 0;
  }
  
  .form-group {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #10385A;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
  }
  .pay-types {
    display: flex;
  justify-content: space-between;
  
  }
  .submit-btn {
    display: block;
    width: 100%;
    padding: 15px;
    background-color: #10385A;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
  }

  .submit-btn:disabled {
    background-color: #b8b8b8;
  }
  
  .submit-btn:hover {
    background-color: #0c2d4a;
  }
  .submit-btn:hover:disabled {
    background-color: #b8b8b8;
  }
  