.locothinkingabouthosting11 {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  line-height: normal;
  letter-spacing: normal;
}
