.logoIcon,
.logoRegmarkLarge {
  overflow: hidden;
  flex-shrink: 0;
}
.logoIcon {
  align-self: stretch;
  height: 24.5px;
  position: relative;
  max-width: 100%;
}
.logoRegmarkLarge {
  width: 172.7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-4xs) var(--padding-4xs-1);
  box-sizing: border-box;
}
.button1 {
  position: relative;
  line-height: 20px;
}
.button,
.button1,
.button3 {
  align-self: stretch;
}
.button {
  border-radius: var(--br-7xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-7xs) var(--padding-base);
}
.button2:hover,
.button4:hover,
.button:hover {
  background-color: var(--color-gainsboro-100);
}
.button3 {
  position: relative;
  line-height: 20px;
}
.button2 {
  align-self: stretch;
  border-radius: var(--br-7xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-7xs) var(--padding-base);
}
.button5 {
  position: relative;
  line-height: 20px;
}
.button4 {
  border-radius: var(--br-7xs);
  border: 1px solid var(--a-others-key-colors-primary);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-mini);
  color: var(--a-others-key-colors-primary);
}
.navigationMenu,
.navigationRight {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.navigationMenu {
  align-self: stretch;
  display: flex;
  gap: var(--gap-11xl);
}
.navigationRight {
  height: 32px;
  display: none;
}
.imAMotoroamer {
  text-decoration: none;
  position: relative;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 117px;
}
.userTypeLabel {
  border-radius: var(--br-9xs);
  background-color: var(--a-teal-0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-xs);
  white-space: nowrap;
}
.imAHost {
  text-decoration: none;
  position: relative;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 67px;
  white-space: nowrap;
}
.userTypeLabel1 {
  border-radius: var(--br-9xs);
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-2xs);
  cursor: pointer;
  color: var(--gray);
}
.audienceSwitch,
.topContainer,
.topheaderLrg,
.userTypeLabel1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.audienceSwitch {
  border-radius: var(--br-9xs);
  justify-content: center;
  text-align: left;
  color: var(--a-others-key-colors-primary);
  font-family: var(--a-label-large);
}
.topContainer,
.topheaderLrg {
  max-width: 100%;
}
.topContainer {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.topheaderLrg {
  align-self: stretch;
  background-color: var(--schemes-on-primary);
  border-bottom: 4px solid var(--a-others-key-colors-primary);
  box-sizing: border-box;
  justify-content: center;
  padding: var(--padding-lg-2) var(--padding-61xl) var(--padding-sm);
  top: 0;
  z-index: 99;
  position: sticky;
  text-align: center;
  font-size: var(--a-body-small-size);
  color: var(--dark-gray);
  font-family: var(--text-small);
}
@media screen and (max-width: 750px) {
  .topheaderLrg {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
}
