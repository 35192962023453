* {
  font-family: 'Montserrat', sans-serif;
}

.App {
}
.App .mobile-container {

}
:root {
  --primary-brand-color: #10385A;
  --secondary-accent-color: #D0E4FF;
  --background-color: #F8F9FA;
  --heading-font: 'Comfortaa', cursive;
  --body-font: 'Montserrat', sans-serif;
}