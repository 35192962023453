.Sites .lander {
  padding: 0px 16px 0px 16px;
  text-align: left;
}

.Sites .lander h1 {
  font-family: 'Comfortaa', cursive;
}

.spinning {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
