.findPay,
.toFindYour {
  margin: 0;
  position: relative;
}
.findPay {
  font-size: inherit;
  line-height: 52px;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.toFindYour {
  align-self: stretch;
  font-size: var(--a-body-xlarge-size);
  letter-spacing: 0.5px;
  line-height: 26px;
  font-weight: 400;
  font-family: var(--a-label-large);
  color: var(--dark-gray);
}
.headlinetext {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  max-width: 100%;
}
.email {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.25px;
  line-height: 20px;
}
.icon,
.jeraldinelocofyuiai {
  position: relative;
  overflow: hidden;
}
.icon {
  height: 18px;
  width: 18px;
  flex-shrink: 0;
  display: none;
}
.jeraldinelocofyuiai {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: var(--a-label-large);
  font-size: var(--a-title-medium-size);
  background-color: transparent;
  height: 24px;
  flex: 1;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: var(--gray);
  text-align: left;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 175px;
  padding: 0;
}
.iconParent {
  align-self: stretch;
  border-radius: var(--default-border-raius);
  background-color: var(--schemes-on-primary);
  border: 1px solid var(--light-border);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-smi);
  gap: var(--gap-3xs);
}
.thisIsA {
  align-self: stretch;
  position: relative;
  font-size: var(--a-body-small-size);
  letter-spacing: 0.4px;
  line-height: 16px;
  color: var(--gray);
  display: none;
}
.inputFieldBase {
  gap: var(--gap-7xs);
}
.inputField,
.inputFieldBase,
.searchbar {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.searchbar {
  align-items: center;
  justify-content: center;
}
.button {
  position: relative;
  font-size: var(--a-title-medium-size);
  letter-spacing: 0.01em;
  line-height: 22px;
  font-weight: 500;
  font-family: var(--a-label-large);
  color: var(--schemes-on-primary);
  text-align: center;
  display: inline-block;
  min-width: 73px;
}
.locobutton {
  cursor: pointer;
  border: 0;
  padding: var(--padding-sm) var(--padding-26xl);
  background-color: var(--a-others-key-colors-primary);
  border-radius: var(--br-7xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.locobutton:hover {
  background-color: var(--color-steelblue-100);
}
.button1,
.icon1 {
  position: relative;
}
.button1 {
  
  
  font-size: var(--a-label-large-size);
  line-height: 18px;
  text-transform: capitalize;
  font-weight: 500;
  font-family: var(--a-label-large);
  color: var(--a-others-key-colors-primary);
  text-align: center;
  display: inline-block;
  
}

.icon1 {
  height: 16px;
  width: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.buttoncontainer,
.locobutton1 {
  display: flex;
  align-items: center;
  min-width: 320px;
}
.locobutton1 {
  
  cursor: pointer;
  border: 1px solid var(--a-others-key-colors-primary);
  padding: var(--padding-7xs) var(--padding-lgi);
  background-color: transparent;
  border-radius: var(--br-7xs);
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-7xs);
  white-space: nowrap;
  min-width: 100%;
}
.locobutton1:hover {
  background-color: var(--color-steelblue-200);
  border: 1px solid var(--color-steelblue-100);
  box-sizing: border-box;
}



.button2 {
  
  color: var(--schemes-on-primary);
  font-size: var(--a-label-large-size);
  line-height: 18px;
  text-transform: capitalize;
  font-weight: 500;
  font-family: var(--a-label-large);
  
  text-align: center;
  display: inline-block;
  
}
.locobutton2 {
  cursor: pointer;
  background-color: var(--a-others-key-colors-primary);
  padding: var(--padding-7xs) var(--padding-lgi);
  /*background-color: transparent;*/
  border-radius: var(--br-7xs);
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-7xs);
  white-space: nowrap;
  min-width: 100%;
}
.locobutton2:hover {
  background-color: var(--color-steelblue-100);
}



.button3 {
  /*position: relative;*/
  font-size: var(--a-title-medium-size);
  letter-spacing: 0.01em;
  line-height: 18px;
  font-weight: 500;
  font-family: var(--a-label-large);
  color: var(--schemes-on-primary);
  text-align: center;
  display: inline-block;
  /*min-width: 73px;*/
}
.locobutton3 {
  cursor: pointer;
  border: 0;
  /*padding: var(--padding-sm) var(--padding-26xl);*/
  padding: var(--padding-7xs) var(--padding-lgi);
  background-color: var(--a-others-key-colors-primary);
  border-radius: var(--br-7xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  min-width: 100%;
}
.locobutton3:hover {
  background-color: var(--color-steelblue-100);
}






.buttoncontainer {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.searchcontainer {
  width: 100%;
  border-radius: var(--br-3xs);
  background-color: var(--color-gray-100);
  justify-content: center;
  padding: var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-base);
  max-width: 360px;
  text-align: left;
  font-size: var(--a-label-large-size);
  color: var(--dark-gray);
  font-family: var(--a-label-large);
}
.container,
.herosectionSitesearch,
.searchcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  width: 448px;
  justify-content: flex-start;
  gap: var(--gap-xl);
  min-width: 320px;
  max-width: 448px;
}
.herosectionSitesearch {
  align-self: stretch;
  overflow: hidden;
  justify-content: center;
  padding: var(--padding-85xl) var(--padding-xl);
  box-sizing: border-box;
  background-image: url(/public/herosection-sitesearch@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  max-width: 100%;
  text-align: center;
  font-size: var(--a-display-medium-size);
  color: var(--a-others-key-colors-primary);
  font-family: var(--a-display-medium);
}
@media screen and (max-width: 750px) {
  .findPay {
    font-size: var(--font-size-15xl);
    line-height: 42px;
  }
  .herosectionSitesearch {
    padding-top: var(--padding-49xl);
    padding-bottom: var(--padding-49xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .findPay {
    font-size: var(--font-size-6xl);
    line-height: 31px;
  }
  .container {
    max-width: 100%;
  }
}
