.vectorIcon {
  width: 300px;
  flex: 1;
  position: relative;
  max-height: 100%;
}
.illustrations {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  padding: 0 var(--padding-xl);
}
.contentwarp,
.graphicwrapper,
.illustrations {
  display: flex;
  align-items: center;
  justify-content: center;
}
.graphicwrapper {
  height: 275.5px;
  flex: 1;
  flex-direction: column;
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-card-mdmax);
}
.contentwarp {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px 19px;
  max-width: 100%;
}
.container,
.contentcomponentOurmission {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.container {
  width: 100%;
  align-items: flex-start;
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-content-contentmaxwidth);
}
.contentcomponentOurmission {
  align-self: stretch;
  box-shadow: 0 0 52.6px rgba(0, 0, 0, 0.03);
  background-color: var(--schemes-on-primary);
  overflow: hidden;
  align-items: center;
  padding: var(--padding-45xl) var(--padding-61xl);
  box-sizing: border-box;
  min-width: 360px;
  max-width: 100%;
}
@media screen and (max-width: 1275px) {
  .container {
    max-width: var(--frame-sizes-content-contentmaxwidth);
  }
}
@media screen and (max-width: 750px) {
  .graphicwrapper {
    gap: var(--gap-base);
    max-width: var(--frame-sizes-card-mdmax);
  }
  .container {
    gap: var(--gap-xl);
  }
  .contentcomponentOurmission {
    gap: var(--gap-4xl);
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .contentcomponentOurmission {
    padding-top: var(--padding-23xl);
    padding-bottom: var(--padding-23xl);
    box-sizing: border-box;
  }
}
