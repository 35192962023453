.forMotoroamers {
  margin: 0;
}
.byMotoroamers {
  margin: 0;
  color: var(--color-cadetblue);
}
.simpleAndCleanContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 52px;
  font-weight: 700;
  font-family: inherit;
}
.powerfulYetClean {
  position: relative;
  font-size: var(--a-title-medium-size);
  letter-spacing: 0.15px;
  line-height: 24px;
  font-family: var(--a-label-large);
  color: var(--dark-gray);
}
.button,
.powerfulYetClean,
.text {
  align-self: stretch;
}
.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.button {
  flex: 1;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 22px;
  font-weight: 500;
}
.locobutton {
  height: 50px;
  flex: 1;
  border-radius: var(--br-7xs);
  border: 1px solid var(--a-others-key-colors-primary);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-sm) var(--padding-8xl);
}
.locobutton1:hover,
.locobutton:hover {
  background-color: var(--color-lightseagreen);
}
.button1 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 22px;
  font-weight: 500;
}
.locobutton1 {
  height: 50px;
  border-radius: var(--br-7xs);
  background-color: var(--a-others-key-colors-primary);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-sm) var(--padding-9xl);
  box-sizing: border-box;
  color: var(--schemes-on-primary);
}
.ctaGroup {
  width: 204px;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: center;
  font-size: var(--a-title-medium-size);
  font-family: var(--a-label-large);
}
.info,
.textwrap {
  display: flex;
  flex-direction: column;
}
.info {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.textwrap {
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: var(--padding-165xl) 0;
  box-sizing: border-box;
  min-width: 340px;
  max-width: 100%;
}
.contentChild {
  height: 526px;
  flex: 1;
  position: relative;
  border-radius: var(--br-5xs);
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  min-width: var(--frame-sizes-content-contentminwith);
}
.content,
.herosectionFormotoroamers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.content {
  flex: 1;
  align-items: center;
  gap: 24px 22px;
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-content-contentmaxwidth);
}
.herosectionFormotoroamers {
  align-self: stretch;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.08);
  background-color: var(--schemes-on-primary);
  overflow: hidden;
  align-items: flex-start;
  padding: var(--padding-xl) var(--padding-61xl);
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: var(--a-display-medium-size);
  color: var(--a-others-key-colors-primary);
  font-family: var(--a-display-medium);
}
@media screen and (max-width: 1275px) {
  .content {
    max-width: var(--frame-sizes-content-contentmaxwidth);
  }
}
@media screen and (max-width: 750px) {
  .simpleAndCleanContainer {
    font-size: var(--font-size-15xl);
    line-height: 42px;
  }
  .textwrap {
    padding-top: var(--padding-101xl);
    padding-bottom: var(--padding-101xl);
    box-sizing: border-box;
  }
  .herosectionFormotoroamers {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .simpleAndCleanContainer {
    font-size: var(--font-size-6xl);
    line-height: 31px;
  }
}
