.siteName,
.sitePreviewImages {
  align-self: stretch;
  position: relative;
  overflow: hidden;
}
.sitePreviewImages {
  height: 183.4px;
  max-width: 100%;
  flex-shrink: 0;
  object-fit: cover;
}
.siteName {
  letter-spacing: 0.15px;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.addressPostcode {
  position: relative;
  font-size: var(--a-body-small-size);
  letter-spacing: 0.39px;
  line-height: 16px;
  color: var(--gray);
  display: inline-block;
  min-width: 125px;
}
.text {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--a-title-medium-size);
  color: var(--color-black);
}
.div,
.siteicons {
  position: relative;
}
.siteicons {
  height: 32px;
  width: 32px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.div {
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
  display: inline-block;
  min-width: 41px;
  white-space: nowrap;
}
.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.siteicons1 {
  height: 32px;
  width: 32px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.div1 {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
  display: inline-block;
  min-width: 35px;
  white-space: nowrap;
}
.content1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-7xs) 0 0;
  gap: var(--gap-11xs);
}
.siteicons2 {
  height: 32px;
  width: 32px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.siteicons2:hover .tooltip {
  display: block;
}
.empty {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
  display: inline-block;
  min-width: 8px;
}
.content2,
.siteIcons {
  display: flex;
  flex-direction: row;
}

.content2 {
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-15xl) 0 0;
  gap: var(--gap-12xs);
}
.content2:hover .tooltip {
  display: block;
}
.siteIcons {
  align-self: stretch;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.button,
.icon {
  position: relative;
}
.button {
  line-height: 14px;
  text-transform: capitalize;
  font-weight: 500;
  display: inline-block;
  min-width: 78px;
  outline: 'none';
  cursor: pointer;
}
.tooltipx {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.icon {
  height: 16px;
  width: 16px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 16px;
}
.cta,
.locobutton {
  display: flex;
  justify-content: center;
  outline: 'none';
}
.locobutton {
  border-radius: var(--br-7xs);
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xs) var(--padding-base);
  gap: var(--gap-7xs);
  outline: 'none';
}
.locobutton:hover {
  background-color: var(--color-gainsboro-100);
}
.cta {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-end;
  text-align: center;
  outline: 'none';
}
.cardsitepreviewsmall,
.siteinfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.siteinfo {
  align-self: stretch;
  padding: var(--padding-9xs) var(--padding-3xs);
  gap: var(--gap-5xs);
}
.cardsitepreviewsmall {
  flex: 1;
  box-shadow: var(--shadow-md);
  border-radius: var(--px-sizes-2xs);
  background-color: var(--schemes-on-primary);
  border: 0.7px solid var(--color-whitesmoke);
  box-sizing: border-box;
  overflow: hidden;
  min-width: var(--frame-sizes-card-smmin);
  max-width: var(--frame-sizes-card-smmax);
  text-align: left;
  font-size: var(--a-body-small-size);
  color: var(--a-others-key-colors-primary);
  font-family: var(--a-label-large);
}
@media screen and (max-width: 1275px) {
  .cardsitepreviewsmall {
    max-width: var(--frame-sizes-card-smmax);
  }
}
@media screen and (max-width: 450px) {
  .cardsitepreviewsmall {
    max-width: var(--frame-sizes-card-smmax);
  }
}
