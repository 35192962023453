.NewSite .lander {
  padding: 0px 16px 0px 16px;
}
.NewSite form textarea {
  height: 300px;
  font-size: 1.5rem;
}
*:focus {
    outline: none;
}

.bay-card {
  
  margin:10px;
  padding: 10px;
  background-color: #fafafa;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  /*
  cursor: pointer;
  text-align: center;
  align-items: center;*/

}

.bay-button-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 14px;
}
.bay-row {
  display:flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
  margin-bottom: 8px;
   padding: 4px;
  background-color:  #D6E9F6;
  color: var(--primary-brand-color);
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  align-items: center;
  font-weight: bold;
}
.bay-item {
  width: 150px;
  align-items: center;
  text-align: center;
}
.bay-item.sm {
  width: 30px;
}
.bay-item.id {
    width: 30px;
    padding: 0px;
  
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}




