.booking-summary {
  /*max-width: 500px;
  margin: 0 auto;*/
  padding: 20px;
  background-color: #F8F9FA;
  font-family: 'Montserrat', sans-serif;
}

.booking-summary h2 {
  font-family: 'Comfortaa', cursive;
  color: #10385A;
  margin-bottom: 20px;
}
.booking-summary h1 {
  font-family: 'Montserrat', sans-serif;
  color: #10385A;
  margin-bottom: -0px;
}

.summary-item {
  /*margin-bottom: 15px;
  line-height: 1;
background-color: beige;*/
height:30px;

align-content:center;


}

.summary-item.right{
text-align: right;
}


.dates {
  padding: 20px 0;
}

.infobox {
  flex: 0 0 auto;
  width: 150px;
  height: 90px;
  margin-right: 0px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  font-family: 'Montserrat', sans-serif;
}

.infobox.full {
  width: 100%;
  height: auto;
  text-align: left;
  margin-bottom: 20px;
}

.link {
  color: "#F18C7E";
}


link:hover {
    color: "#F18C7E";
    textDecoration: "underline #F18C7E"
  }






.make-payment-btn {
  padding: 15px 30px;
  background-color: var(--primary-brand-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  width: 100%;
}

.make-payment-btn:hover {
    background-color: #0c2d4a;
}