.backgroundIcon {
  height: 526px;
  flex: 1;
  position: relative;
  border-radius: var(--br-5xs);
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  min-width: var(--frame-sizes-content-contentminwith);
  min-height: 526px;
}
.content,
.herosectionHostingmadesimple {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.content {
  flex: 1;
  align-items: center;
  gap: 24px 22px;
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-content-contentmaxwidth);
}
.herosectionHostingmadesimple {
  align-self: stretch;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.08);
  background-color: var(--schemes-on-primary);
  overflow: hidden;
  align-items: flex-start;
  padding: var(--padding-xl) var(--padding-61xl);
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: var(--a-display-medium-size);
  color: var(--dark-gray);
  font-family: var(--a-display-medium);
}
@media screen and (max-width: 1275px) {
  .content {
    max-width: var(--frame-sizes-content-contentmaxwidth);
  }
}
@media screen and (max-width: 750px) {
  .herosectionHostingmadesimple {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
}
