

.tooltip:hover .tooltiptext {
  visibility: visible;
}


.calendar-date-selection {
  padding: 20px;
  background-color: var(--background-color);
}


.calendar-view {
  margin: 20px auto 20px auto;
  /*background-color: #F8F9FA;*/
  font-family: 'Montserrat', sans-serif;
  max-width: 300px;
  min-width: 300px;
 /*flex: 0 0 auto;*/
  padding: 10px;
  
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  /*transition: all 0.3s ease;*/
}

/*
.date-card {
  flex: 0 0 auto;
  width: 110px;
  height: 185px;
  margin-right: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
}
  */


.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.calendar-header button {
  background-color: #10385A;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
}

.date-selection-btn {
  /*  position: absolute;*/
  top: 20px;
  right: 20px;
  padding: 10px 15px;
  background-color: var(--secondary-accent-color);
  color: var(--primary-brand-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--body-font);
}

.calendar-header h2 {
  font-family: 'Comfortaa', cursive;
  color: #10385A;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
}

.calendar-day {
  padding: 7px;
  width: 33px;
  aspect-ratio: 1 / 1;
  text-align: center;
  border: 0px solid #ddd;
  cursor: pointer;
  background-color: white;
  /*border-bottom: 1px dotted black;
  font-weight: bold;*/
}



.calendar-day .tooltiptext {
  visibility: hidden;
  white-space:nowrap;
  width: auto;
  background-color: var(--secondary-accent-color);
  color: var(--primary-brand-color);
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  margin:15px 15px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.calendar-day:hover .tooltiptext {
  visibility: visible;
}


.calendar-day.day-name {
  /*font-weight: bold;
  background-color: #D0E4FF;*/
  color: #10385A;
  font-size: 16px;
  
}

.calendar-day.empty {
  background-color: #ffffff;
  cursor: default;
  
  
}

.calendar-day.selected {
  border: 1px solid var(--primary-brand-color);;
  border-radius: 100%;
  padding: 5px;
  background-color: #d1f4fa;
  /*font-weight: bold;*/
}
.calendar-day.unavailable {
  border-radius: 100%;
  padding: 5px;
  background-color: #efefef;
  color: #aaa;
  /*font-weight: normal;*/
}

.confirm-dates-btn {
  padding: 15px 30px;
  background-color: var(--primary-brand-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: bold;
  /*transition: background-color 0.3s ease;*/
  width: 100%;
}

.confirm-dates-btn:hover {
  background-color: #0c2d4a;
}

.summary {
  margin-top: 20px;
  text-align: center;
}



.features-card {
  flex: 0 0 auto;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
  padding-top: 2px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  
 
}

.features-card.darker {
   background-color: rgb(249, 249, 249);
   box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
   margin-bottom: 4px;
 
}




@media (max-width: 768px) {
  .calendar-grid {
    gap: 5px;
  }

  .calendar-day {
    padding: 5px;
    font-size: 14px;
  }
}
