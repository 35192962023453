.logoIcon {
  align-self: stretch;
  height: 37.4px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.logoRegmarkLarge {
  width: 271.8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-smi-2) var(--padding-3xl) var(--padding-smi-3)
    var(--padding-smi);
  box-sizing: border-box;
}
.airestopLimitedCompany {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 16px;
}
.vectorIcon {
  position: absolute;
  height: 96%;
  width: 96%;
  top: 2%;
  right: 2%;
  bottom: 2%;
  left: 2%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.entypoSocialfacebookWithCi,
.vectorIcon1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  overflow: hidden;
}
.vectorIcon1 {
  height: 96%;
  width: 96%;
  top: 2%;
  right: 2%;
  bottom: 2%;
  left: 2%;
  max-width: 100%;
  max-height: 100%;
}
.entypoSocialtwitterWithCir,
.vectorIcon2 {
  position: absolute;
  top: 0;
  left: 100px;
  width: 30px;
  height: 30px;
  overflow: hidden;
}
.vectorIcon2 {
  height: 96%;
  width: 96%;
  top: 2%;
  right: 2%;
  bottom: 2%;
  left: 2%;
  max-width: 100%;
  max-height: 100%;
}
.entypoSocialinstagramWithC {
  position: absolute;
  top: 0;
  left: 50px;
  width: 30px;
  height: 30px;
  overflow: hidden;
}
.socialIcons {
  width: 130px;
  height: 30px;
  position: relative;
  display: none;
}
.airestopLimitedAll {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 16px;
}
.companyInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) var(--padding-12xs-1) 0;
  box-sizing: border-box;
  gap: var(--gap-5xs);
  min-width: 320px;
}
.aboutUs,
.company1 {
  font-family: var(--a-label-large);
}
.company1 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.15px;
  line-height: 24px;
}
.aboutUs {
  text-decoration: none;
  font-size: var(--a-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
}
.aboutUs,
.howWeWork,
.privacyNotice,
.termsOfUse {
  align-self: stretch;
  position: relative;
}
.howWeWork {
  display: none;
}
.company {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
  font-size: var(--a-title-medium-size);
  font-family: var(--font-roboto);
}
.contactUs,
.support1 {
  align-self: stretch;
  position: relative;
}
.support1 {
  font-size: var(--a-title-medium-size);
  letter-spacing: 0.15px;
  line-height: 24px;
}
.contactUs {
  text-decoration: none;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
}
.supportCenter {
  display: none;
}
.accessibility,
.faq,
.supportCenter {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
}
.faq {
  text-decoration: none;
  color: inherit;
}
.accessibility {
  display: none;
}
.support {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.hosting1 {
  align-self: stretch;
  position: relative;
  font-size: var(--a-title-medium-size);
  letter-spacing: 0.15px;
  line-height: 24px;
}
.becomeAHost,
.quarantineRules,
.thinkingAboutHosting,
.tips {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
}
.quarantineRules,
.tips {
  display: none;
}
.content,
.hosting,
.menus {
  display: flex;
  align-items: flex-start;
}
.hosting {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.content,
.menus {
  flex-direction: row;
  flex-wrap: wrap;
}
.menus {
  width: 640px;
  justify-content: flex-start;
  padding: 0 var(--padding-3xl) 0 0;
  box-sizing: border-box;
  gap: var(--gap-5xl);
  min-width: var(--frame-sizes-card-smmax);
  max-width: var(--frame-sizes-card-mdmax);
  font-size: var(--a-label-large-size);
}
.content {
  width: 100%;
  justify-content: space-between;
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-content-contentmaxwidth);
  gap: var(--gap-xl);
}
.footerpublicLrg {
  align-self: stretch;
  background-color: var(--a-others-key-colors-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-13xl) var(--padding-61xl) var(--padding-45xl);
  box-sizing: border-box;
  min-height: 220px;
  max-width: 100%;
  text-align: left;
  font-size: var(--a-body-small-size);
  color: var(--schemes-on-primary);
  font-family: var(--a-label-large);
}
@media screen and (max-width: 1275px) {
  .content {
    max-width: var(--frame-sizes-content-contentmaxwidth);
  }
}
@media screen and (max-width: 750px) {
  .menus {
    max-width: var(--frame-sizes-card-mdmax);
    min-width: var(--frame-sizes-card-smmax);
  }
  .footerpublicLrg {
    gap: var(--gap-3xl);
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
}
