body {
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  


}
/*
h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}
input[type=file] {
  width: 100%;
}

:root {
  /* fonts */
  --m3-headline-large: Roboto;
  --text-small: Inter;
  --header: Comfortaa;
  --font-changa-one: "Changa One";
  --subheader: Montserrat;

  /* font sizes */
  --text-large-bolder-size: 16px;
  --font-size-xl: 20px;
  --subheader-size: 18px;
  --font-size-base-8: 16.8px;
  --text-small-size: 12px;
  --header-size: 36px;
  --font-size-3xl: 22px;
  --font-size-10xl: 29px;
  --text-medium-size: 14px;
  --font-size-lgi-4: 19.4px;
  --font-size-10xl-1: 29.1px;
  --font-size-4xl: 23px;
  --m3-headline-large-size: 32px;
  --font-size-lgi: 19px;
  --font-size-7xl: 26px;
  --font-size-2xs: 11px;

  /* Colors */
  --color-darkslategray-100: #434343;
  --dark-gray: #334158;
  --brand-primary: #10385a;
  --dominant: #fff;
  --color-gray-100: rgba(255, 255, 255, 0.8);
  --color-gray-200: rgba(255, 255, 255, 0.2);
  --studio-darkmode-enabledlabel-e8effd: #e8effd;
  --color-gainsboro-100: #e7e7e7;
  --light-border: #e2e4ea;
  --gray: #676f82;
  --color-slategray-100: #576074;
  --color-black: #000;
  --neutral-neutral-50: #f9fafb;
  --color-whitesmoke-100: #f9f9f9;
  --color-royalblue: #457eff;
  --color-steelblue: #426b8c;
  --light-background: #f3f6fd;

  /* Spacing */
  --default-border-raius: 4px;

  /* Gaps */
  --gap-24xl-5: 43.5px;
  --gap-3xl: 22px;
  --gap-3xs-9: 9.9px;
  --gap-sm: 14px;
  --gap-xl: 20px;
  --gap-base-3: 15.3px;
  --gap-6xs-1: 6.1px;
  --gap-61xl: 80px;
  --gap-21xl: 40px;
  --gap-53xl: 72px;
  --gap-17xl: 36px;
  --gap-lg: 18px;
  --gap-5xl: 24px;
  --gap-7xs: 6px;
  --gap-11xl: 30px;
  --gap-mini: 15px;
  --gap-11xs: 2px;
  --gap-3xs: 10px;
  --gap-27xl: 46px;
  --gap-4xl: 23px;
  --gap-2xl: 21px;
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-9xs: 4px;

  /* Paddings */
  --padding-14xl: 33px;
  --padding-65xl: 84px;
  --padding-23xl: 42px;
  --padding-xl: 20px;
  --padding-10xs-6: 2.6px;
  --padding-53xl: 72px;
  --padding-28xl: 47px;
  --padding-73xl: 92px;
  --padding-27xl: 46px;
  --padding-5xl: 24px;
  --padding-base: 16px;
  --padding-xs: 12px;
  --padding-21xl: 40px;
  --padding-78xl: 97px;
  --padding-29xl: 48px;
  --padding-7xl: 26px;
  --padding-31xl: 50px;
  --padding-2xl: 21px;
  --padding-13xl: 32px;
  --padding-3xl: 22px;
  --padding-61xl: 80px;
  --padding-33xl: 52px;
  --padding-67xl: 86px;
  --padding-37xl: 56px;
  --padding-7xs: 6px;
  --padding-sm: 14px;
  --padding-9xl: 28px;
  --padding-5xs: 8px;
  --padding-smi: 13px;
  --padding-xl-5: 20.5px;

  /* Border radiuses */
  --br-smi-2: 12.2px;
  --br-5xs: 8px;
  --br-11xl: 30px;
  --br-2xs-5: 10.5px;
  --br-7xs-3: 5.3px;
  --br-3xs: 10px;
  --br-7xs: 6px;
  --br-9xs: 4px;
}



