.ourServices,
.weHelpingLandowners {
  align-self: stretch;
  position: relative;
}
.ourServices {
  margin: 0;
  font-size: inherit;
  line-height: 40px;
  font-weight: 700;
  font-family: inherit;
}
.weHelpingLandowners {
  font-size: var(--a-title-medium-size);
  letter-spacing: 0.15px;
  line-height: 24px;
  font-family: var(--a-label-large);
  color: var(--dark-gray);
}
.textWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.ctaChild {
  align-self: stretch;
  height: 0.7px;
  position: relative;
  border-top: 0.7px solid var(--studio-lightmode-lightseparator-ecf0f6);
  box-sizing: border-box;
}
.button {
  position: relative;
  font-size: var(--a-title-medium-size);
  letter-spacing: 0.01em;
  line-height: 22px;
  font-weight: 500;
  font-family: var(--a-label-large);
  color: var(--schemes-on-primary);
  text-align: center;
}
.locobutton {
  cursor: pointer;
  border: 0;
  padding: var(--padding-sm) var(--padding-8xl);
  background-color: var(--a-others-key-colors-primary);
  border-radius: var(--br-7xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.locobutton:hover {
  background-color: var(--color-steelblue-100);
}
.cta,
.infomation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cta {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 var(--padding-307xl) 0 0;
  gap: var(--gap-base);
}
.infomation {
  flex: 1;
  justify-content: center;
  gap: var(--gap-13xl);
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-card-mdmax);
}
.contentwarp,
.graphicwrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.graphicwrapper {
  flex: 0.8665;
  padding: 0 var(--padding-23xl);
  box-sizing: border-box;
  gap: var(--gap-13xl);
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-card-mdmax);
  min-height: 376px;
  font-size: var(--a-label-large-size);
  color: var(--gray);
  font-family: var(--a-label-large);
}
.contentwarp {
  align-self: stretch;
  gap: 40px 19px;
  max-width: 100%;
}
.container,
.contentcomponentOurservices {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.container {
  width: 100%;
  align-items: flex-start;
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-content-contentmaxwidth);
}
.contentcomponentOurservices {
  align-self: stretch;
  box-shadow: 0 0 52.6px rgba(0, 0, 0, 0.03);
  background-color: var(--schemes-on-primary);
  overflow: hidden;
  align-items: center;
  padding: var(--padding-45xl) var(--padding-61xl);
  box-sizing: border-box;
  min-width: 360px;
  max-width: 100%;
  text-align: left;
  font-size: var(--a-headline-large-size);
  color: var(--color-darkslategray-100);
  font-family: var(--a-display-medium);
}
@media screen and (max-width: 1275px) {
  .graphicwrapper {
    padding-left: var(--padding-2xl);
    padding-right: var(--padding-2xl);
    box-sizing: border-box;
  }
  .container {
    max-width: var(--frame-sizes-content-contentmaxwidth);
  }
}
@media screen and (max-width: 750px) {
  .ourServices {
    font-size: var(--font-size-7xl);
    line-height: 32px;
  }
  .cta {
    padding-right: var(--padding-144xl);
    box-sizing: border-box;
  }
  .graphicwrapper,
  .infomation {
    gap: var(--gap-base);
    max-width: var(--frame-sizes-card-mdmax);
  }
  .container {
    gap: var(--gap-xl);
  }
  .contentcomponentOurservices {
    gap: var(--gap-4xl);
    padding: var(--padding-23xl) var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .ourServices {
    font-size: var(--font-size-lgi);
    line-height: 24px;
  }
  .cta {
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
