.payment-donation {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #F8F9FA;
  font-family: 'Montserrat', sans-serif;
}

.payment-donation h2, .payment-donation h3 {
  font-family: 'Comfortaa', cursive;
  color: #10385A;
  margin-bottom: 20px;
}

.total-amount {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #10385A;
}

.donation-section {
  margin-bottom: 20px;
}

.donation-slider {
  width: 100%;
  margin-bottom: 10px;
}

.continue-btn {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #10385A;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

.continue-btn:hover {
  background-color: #0c2d4a;
}
