.whatOurGuests {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  line-height: 52px;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.titletext {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.graphicGroupChild {
  width: 123.9px;
  height: 120px;
  position: relative;
  border-radius: 50%;
  background-color: var(--a-blue-0);
  z-index: 1;
}
.vectorIcon {
  align-self: stretch;
  height: 85.4px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
.graphicGroup,
.usericons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.usericons {
  width: 124px;
  justify-content: center;
  z-index: 2;
  margin-top: -130px;
}
.graphicGroup {
  align-self: stretch;
  flex: 1;
  justify-content: flex-end;
}
.theBigOxmox {
  align-self: stretch;
  height: 96px;
  position: relative;
  letter-spacing: 0.5px;
  line-height: 24px;
  display: inline-block;
}
.vectorIcon1,
.vectorIcon2,
.vectorIcon3,
.vectorIcon4,
.vectorIcon5 {
  height: 19px;
  width: 20px;
  position: relative;
  min-height: 19px;
}
.vectorParent {
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.guestHost,
.name {
  position: relative;
  display: inline-block;
}
.name {
  align-self: stretch;
  height: 24px;
  letter-spacing: 0.15px;
  line-height: 24px;
}
.guestHost {
  height: 16px;
  font-size: var(--a-body-small-size);
  letter-spacing: 0.4px;
  line-height: 16px;
  color: var(--gray);
  text-align: left;
}
.cardtestimonialCard,
.nameParent {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.nameParent {
  align-self: stretch;
  border-top: 1px solid var(--light-border);
  display: flex;
  padding: var(--padding-xs) 0 0;
  color: var(--color-black);
}
.cardtestimonialCard {
  height: 366px;
  width: 296px;
  box-shadow: var(--shadow-md);
  border-radius: var(--px-sizes-2xs);
  background-color: var(--schemes-on-primary);
  display: none;
  padding: var(--padding-5xl) var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-5xl);
  min-width: var(--frame-sizes-card-smmin);
  max-width: var(--frame-sizes-card-smmax);
}
.cardwrap {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-145xl);
  gap: 23px 30px;
  font-size: var(--a-title-medium-size);
  color: var(--gray);
  font-family: var(--a-label-large);
}
.content,
.testimonialsectionHosts {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-21xl);
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-content-contentmaxwidth);
}
.testimonialsectionHosts {
  align-self: stretch;
  background-color: var(--schemes-on-primary);
  flex-direction: row;
  padding: var(--px-sizes-m) var(--padding-61xl);
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: var(--a-display-medium-size);
  color: var(--dark-gray);
  font-family: var(--a-display-medium);
}
@media screen and (max-width: 1275px) {
  .cardwrap {
    padding-left: var(--padding-63xl);
    padding-right: var(--padding-63xl);
    box-sizing: border-box;
  }
  .content {
    max-width: var(--frame-sizes-content-contentmaxwidth);
  }
}
@media screen and (max-width: 750px) {
  .whatOurGuests {
    font-size: var(--font-size-15xl);
    line-height: 42px;
  }
  .cardwrap {
    gap: var(--gap-mini);
    padding-left: var(--padding-22xl);
    padding-right: var(--padding-22xl);
    box-sizing: border-box;
  }
  .content {
    gap: var(--gap-xl);
  }
  .testimonialsectionHosts {
    gap: var(--frame-sizes-content-contentmargin);
    padding: var(--px-sizes-m) var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .whatOurGuests {
    font-size: var(--font-size-6xl);
    line-height: 31px;
  }
  .cardtestimonialCard {
    max-width: var(--frame-sizes-card-smmax);
  }
}
