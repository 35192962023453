.aire {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 44px;
  font-weight: 700;
  font-family: inherit;
}
.air,
.aire,
.headertext {
  align-self: stretch;
}
.air {
  position: relative;
  font-size: var(--a-label-large-size);
  letter-spacing: 0.25px;
  line-height: 20px;
  font-family: var(--a-label-large);
  color: var(--gray);
}
.headertext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
  flex-shrink: 0;
  font-size: var(--a-display-small-size);
  color: var(--a-others-key-colors-primary);
  font-family: var(--a-display-medium);
}
.noun,
.stopoverChild {
  align-self: stretch;
  position: relative;
}
.stopoverChild {
  height: 0.8px;
  border-top: 0.8px solid var(--a-teal-500);
  box-sizing: border-box;
}
.noun {
  font-size: var(--a-label-large-size);
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
}
.aDesignatedStopoverAreaFor {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-2xl);
}
.aDesignatedStopoverContainer {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.aPlaceWhereMotorhomesAndC {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-2xl);
}
.aPlaceWhereContainer {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.contentgraphic,
.stopover {
  border-radius: var(--br-5xs);
  background-color: var(--schemes-on-primary);
  display: flex;
  justify-content: center;
}
.stopover {
  flex: 1;
  box-shadow: 0 4px 6px -4px rgba(0, 0, 0, 0.1),
    0 10px 15px -3px rgba(0, 0, 0, 0.1);
  border: 2px solid var(--a-teal-500);
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-mid) var(--padding-lg);
  gap: var(--gap-5xs);
  max-width: 100%;
}
.contentgraphic {
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  min-width: var(--frame-sizes-card-smmin);
  max-width: var(--frame-sizes-card-mdmax);
}
.contentwarp,
.graphicwrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.graphicwrapper {
  flex: 0.7965;
  flex-direction: column;
  padding: 0 var(--padding-45xl);
  box-sizing: border-box;
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-card-mdmax);
}
.contentwarp {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px 19px;
  max-width: 100%;
}
.container,
.contentcomponentWhatisanaire {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.container {
  width: 100%;
  align-items: flex-start;
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-content-contentmaxwidth);
}
.contentcomponentWhatisanaire {
  align-self: stretch;
  box-shadow: 0 0 52.6px rgba(0, 0, 0, 0.03);
  background-color: var(--schemes-on-primary);
  overflow: hidden;
  align-items: center;
  padding: var(--padding-45xl) var(--padding-61xl);
  box-sizing: border-box;
  min-width: 360px;
  max-width: 100%;
  text-align: left;
  font-size: var(--a-title-medium-size);
  color: var(--gray);
  font-family: var(--a-label-large);
}
@media screen and (max-width: 1275px) {
  .container {
    max-width: var(--frame-sizes-content-contentmaxwidth);
  }
}
@media screen and (max-width: 750px) {
  .aire {
    font-size: var(--font-size-10xl);
    line-height: 35px;
  }
  .contentgraphic,
  .graphicwrapper {
    max-width: var(--frame-sizes-card-mdmax);
  }
  .graphicwrapper {
    gap: var(--gap-base);
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
  .container {
    gap: var(--gap-xl);
  }
  .contentcomponentWhatisanaire {
    gap: var(--gap-4xl);
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .aire {
    font-size: var(--font-size-3xl);
    line-height: 26px;
  }
  .contentcomponentWhatisanaire {
    padding-top: var(--padding-23xl);
    padding-bottom: var(--padding-23xl);
    box-sizing: border-box;
  }
}
