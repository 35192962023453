@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --a-label-large: Montserrat;
  --font-roboto: Roboto;
  --a-display-medium: Comfortaa;
  --text-small: Inter;

  /* font sizes */
  --a-label-large-size: 14px;
  --a-title-medium-size: 16px;
  --a-body-small-size: 12px;
  --a-display-medium-size: 42px;
  --font-size-15xl: 34px;
  --font-size-6xl: 25px;
  --a-body-xlarge-size: 18px;
  --a-headline-large-size: 32px;
  --font-size-7xl: 26px;
  --font-size-lgi: 19px;
  --a-display-small-size: 36px;
  --font-size-10xl: 29px;
  --font-size-3xl: 22px;

  /* Colors */
  --color-darkslategray-100: #434343;
  --dark-gray: #334158;
  --a-others-key-colors-primary: #10385a;
  --schemes-on-primary: #fff;
  --color-gainsboro-100: #e7e7e7;
  --light-border: #e2e4ea;
  --gray: #676f82;
  --color-slategray-100: #576074;
  --color-black: #000;
  --a-blue-0: #d9edff;
  --a-others-ref-neutral-neutral99: #fefcfd;
  --color-gray-100: rgba(255, 255, 255, 0.8);
  --color-whitesmoke: rgba(249, 249, 249, 0.98);
  --color-steelblue-100: #426b8c;
  --color-steelblue-200: rgba(66, 107, 140, 0.09);
  --a-teal-0: #e8f9ff;
  --studio-lightmode-lightseparator-ecf0f6: #ecf0f6;
  --color-lightseagreen: #00ac92;
  --a-teal-500: #7bbbd0;
  --color-cadetblue: #34a4bd;

  /* Spacing */
  --frame-sizes-content-contentminwith: 320px;
  /*--frame-sizes-content-contentmaxwidth: 1280px;
  --frame-sizes-content-contentmaxwidth: 1440px;*/
  --frame-sizes-content-contentmaxwidth: 1440px;
  --frame-sizes-card-smmax: 448px;
  --frame-sizes-card-mdmax: 640px;
  --frame-sizes-content-contentmargin: 32px;
  --px-sizes-m: 64px;
  --px-sizes-2xs: 8px;
  --frame-sizes-card-smmin: 256px;
  --px-sizes-xs: 16px;
  --default-border-raius: 4px;

  /* Gaps */
  --gap-3xl: 22px;
  --gap-xl: 20px;
  --gap-5xl: 24px;
  --gap-sm: 14px;
  --gap-5xs: 8px;
  --gap-base: 16px;
  --gap-21xl: 40px;
  --gap-4xl: 23px;
  --gap-mini: 15px;
  --gap-7xs: 6px;
  --gap-12xs: 1px;
  --gap-11xs: 2px;
  --gap-3xs: 10px;
  --gap-11xl: 30px;
  --gap-13xl: 32px;
  --gap-8xs: 5px;
  --gap-mid: 17px;
  --gap-9xs: 4px;

  /* Paddings */
  --padding-13xl: 32px;
  --padding-61xl: 80px;
  --padding-45xl: 64px;
  --padding-21xl: 40px;
  --padding-3xl: 22px;
  --padding-xl: 20px;
  --padding-12xs-1: 0.1px;
  --padding-smi-2: 12.2px;
  --padding-smi-3: 12.3px;
  --padding-smi: 13px;
  --padding-23xl: 42px;
  --padding-9xs: 4px;
  --padding-5xl: 24px;
  --padding-base: 16px;
  --padding-xs: 12px;
  --padding-2xl: 21px;
  --padding-44xl: 63px;
  --padding-49xl: 68px;
  --padding-12xs: 1px;
  --padding-11xs: 2px;
  --padding-51xl: 70px;
  --padding-3xs: 10px;
  --padding-7xs: 6px;
  --padding-15xl: 34px;
  --padding-85xl: 104px;
  --padding-lgi: 19px;
  --padding-sm: 14px;
  --padding-26xl: 45px;
  --padding-5xs: 8px;
  --padding-lg-2: 18.2px;
  --padding-2xs: 11px;
  --padding-mini: 15px;
  --padding-4xs: 9px;
  --padding-4xs-1: 8.1px;
  --padding-145xl: 164px;
  --padding-22xl: 41px;
  --padding-63xl: 82px;
  --padding-50xl: 69px;
  --padding-307xl: 326px;
  --padding-144xl: 163px;
  --padding-8xl: 27px;
  --padding-154xl: 173px;
  --padding-93xl: 112px;
  --padding-77xl: 96px;
  --padding-78xl: 97px;
  --padding-29xl: 48px;
  --padding-10xs: 3px;
  --padding-mid: 17px;
  --padding-lg: 18px;
  --padding-165xl: 184px;
  --padding-101xl: 120px;
  --padding-9xl: 28px;
  --padding-290xl: 309px;
  --padding-135xl: 154px;
  --padding-218xl: 237px;
  --padding-99xl: 118px;
  --padding-7xl: 26px;
  --padding-31xl: 50px;
  --padding-6xl: 25px;
  --padding-128xl: 147px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-7xs: 6px;
  --br-3xs: 10px;
  --br-9xs: 4px;
  --br-7xs-3: 5.3px;

  /* Effects */
  --shadow-md: 0px 2px 4px -2px rgba(0, 0, 0, 0.1),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-lg: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1));
}
