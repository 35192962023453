.featuredStops {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 52px;
  font-weight: 700;
  font-family: inherit;
}
.cardwrap,
.titletext {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.cardwrap {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 23px 30.7px;
  font-size: var(--a-body-small-size);
  color: var(--a-others-key-colors-primary);
  font-family: var(--a-label-large);
}
.content,
.featuresectionFeaturedsites {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.content {
  width: 100%;
  gap: var(--gap-21xl);
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-content-contentmaxwidth);
}
.featuresectionFeaturedsites {
  align-self: stretch;
  background-color: var(--a-others-ref-neutral-neutral99);
  padding: var(--px-sizes-m) var(--padding-61xl);
  text-align: left;
  font-size: var(--a-display-medium-size);
  color: var(--dark-gray);
  font-family: var(--a-display-medium);
}
@media screen and (max-width: 1275px) {
  .content {
    max-width: var(--frame-sizes-content-contentmaxwidth);
  }
}
@media screen and (max-width: 750px) {
  .featuredStops {
    font-size: var(--font-size-15xl);
    line-height: 42px;
  }
  .cardwrap {
    gap: var(--gap-mini);
  }
  .content {
    gap: var(--gap-xl);
  }
  .featuresectionFeaturedsites {
    gap: var(--frame-sizes-content-contentmargin);
    padding: var(--px-sizes-m) var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .featuredStops {
    font-size: var(--font-size-6xl);
    line-height: 31px;
  }
}
