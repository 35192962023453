.powerfulYetClean,
.simpleAndClean {
  align-self: stretch;
  position: relative;
}
.simpleAndClean {
  margin: 0;
  font-size: inherit;
  line-height: 52px;
  font-weight: 700;
  font-family: inherit;
}
.powerfulYetClean {
  font-size: var(--a-title-medium-size);
  letter-spacing: 0.15px;
  line-height: 24px;
  font-family: var(--a-label-large);
}
.text {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.button {
  position: relative;
  font-size: var(--a-title-medium-size);
  letter-spacing: 0.01em;
  line-height: 22px;
  font-weight: 500;
  font-family: var(--a-label-large);
  color: var(--a-others-key-colors-primary);
  text-align: center;
}
.locobutton {
  border-radius: var(--br-7xs);
  border: 1px solid var(--a-others-key-colors-primary);
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-8xl);
}
.locobutton1:hover,
.locobutton:hover {
  background-color: var(--color-lightseagreen);
}
.button1 {
  position: relative;
  font-size: var(--a-title-medium-size);
  letter-spacing: 0.01em;
  line-height: 22px;
  font-weight: 500;
  font-family: var(--a-label-large);
  color: var(--schemes-on-primary);
  text-align: center;
  display: inline-block;
  min-width: 126px;
}
.ctaGroup,
.locobutton1 {
  display: flex;
  flex-direction: row;
}
.locobutton1 {
  border-radius: var(--br-7xs);
  background-color: var(--a-others-key-colors-primary);
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: var(--padding-sm) var(--padding-8xl);
  white-space: nowrap;
}
.ctaGroup {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.info,
.textwrap {
  display: flex;
  flex-direction: column;
}
.info {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.textwrap {
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: var(--padding-154xl) 0;
  box-sizing: border-box;
  min-width: 340px;
  max-width: 100%;
  text-align: left;
  font-size: var(--a-display-medium-size);
  color: var(--dark-gray);
  font-family: var(--a-display-medium);
}
@media screen and (max-width: 750px) {
  .simpleAndClean {
    font-size: var(--font-size-15xl);
    line-height: 42px;
  }
  .textwrap {
    padding-top: var(--padding-93xl);
    padding-bottom: var(--padding-93xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .simpleAndClean {
    font-size: var(--font-size-6xl);
    line-height: 31px;
  }
}
