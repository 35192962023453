.Home .lander2 {
  padding: 32px 32px 0px 32px;
  text-align: left;
  min-height: calc(100vh - 168px);

}
.Home .mobile-lander2 {
  padding: 32px 32px 0px 32px;
  text-align: left;
  min-height: calc(100vh - 168px);

}
.Home .footer a {
  font-family: "Montserrat", Montserrat;
  font-weight: 400;
  color: white;
font-size: 12px;
}


.Home .lander2 p {
  font-family: "Montserrat", Montserrat;
  font-weight: 400;
  color: #10385A;
font-size: 14px;
padding: 0px;
margin: 0px;

/*


-webkit-text-stroke: 1px lightGrey;
-webkit-text-fill-color: white;
*/
}
.Home .mobile-lander2 p {
  font-family: "Montserrat", Montserrat;
  font-weight: 400;
  color: #10385A;
font-size: 14px;
padding: 0px;
margin: 0px;
/*
-webkit-text-stroke: 1px #ddd;
-webkit-text-fill-color: white;
*/
}
.Home .lander2 a {
  font-family: "Montserrat", Montserrat;
  font-weight: 400;
  color: white;
font-size: 12px;/*

-webkit-text-stroke: 1px lightGrey;
-webkit-text-fill-color: white;
*/
}
.Home .mobile-lander2 a {
  font-family: "Montserrat", Montserrat;
  font-weight: 400;
  color: white;
font-size: 12px;
/*
-webkit-text-stroke: 1px #ddd;
-webkit-text-fill-color: white;
*/
}


.Home .lander2 h1 {
  font-family: 'Comfortaa', cursive;
  font-weight: 900;
  color: white;
font-size: 42px;
text-align: left;

}
.Home .mobile-lander2 h1 {
  font-family: 'Comfortaa', cursive;
  font-weight: 900;
  color: white;
font-size: 32px;
text-align: left;


}
.Home .lander2 h2 {
  font-family: 'Comfortaa', cursive;
  font-weight: 900;
  color: white;
font-size: 32px;
text-align: left;

}
.Home .mobile-lander2 h2 {
  font-family: 'Comfortaa', cursive;
  font-weight: 900;
  color: white;
font-size: 22px;
text-align: left;


}









.Home .lander {
  padding: 60px 0px;
  text-align: left;

  background-image: url(../images/large_camper.jpeg);

  height: calc(100vh - 75px);

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Home .mobile-lander {
  padding: 60px 0px;
  text-align: left;

  background-image: url(../images/mobile_camper.jpeg);

  height: calc(100vh - 75px);


  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.Home .lander p {
  font-family: "Montserrat", Montserrat;
  font-weight: 400;
  color: #10385A;
font-size: 14px;
padding: 0px;
margin: 0px;

/*


-webkit-text-stroke: 1px lightGrey;
-webkit-text-fill-color: white;
*/
}
.Home .mobile-lander p {
  font-family: "Montserrat", Montserrat;
  font-weight: 400;
  color: #10385A;
font-size: 14px;
padding: 0px;
margin: 0px;
/*
-webkit-text-stroke: 1px #ddd;
-webkit-text-fill-color: white;
*/
}
.Home .lander a {
  font-family: "Montserrat", Montserrat;
  font-weight: 400;
  color: white;
font-size: 12px;/*

-webkit-text-stroke: 1px lightGrey;
-webkit-text-fill-color: white;
*/
}
.Home .mobile-lander a {
  font-family: "Montserrat", Montserrat;
  font-weight: 400;
  color: white;
font-size: 12px;
/*
-webkit-text-stroke: 1px #ddd;
-webkit-text-fill-color: white;
*/
}


.Home .lander h1 {
  font-family: 'Comfortaa', cursive;
  font-weight: 900;
  color: white;
font-size: 42px;
text-align: center;

}
.Home .mobile-lander h1 {
  font-family: 'Comfortaa', cursive;
  font-weight: 900;
  color: white;
font-size: 36px;
text-align: center;


}.Home .lander h2 {
  font-family: 'Comfortaa', cursive;
  font-weight: 900;
  color: white;
font-size: 36px;
text-align: center;

}
.Home .mobile-lander h2 {
  font-family: 'Comfortaa', cursive;
  font-weight: 900;
  color: white;
font-size: 32px;
text-align: center;


}
