.trustedPartners {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 52px;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.title {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
}
.partnerLogoIcon {
  height: 80px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
.stripe {
  height: 60px;
  width: 200px;
  filter: var(--shadow-lg);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-base);
  box-sizing: border-box;
  min-width: 200px;
}
.campraUkLogopngIcon {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.campra,

.partnerLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.partnerLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.partnerLogo {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
}
.campra {
  
  width: 200px;
  filter: var(--shadow-lg);
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
  min-width: 200px;
}
.angusTourismCooperativeLogoIcon {
  align-self: stretch;
  height: 40px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.atc,
.partnerLogo1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.partnerLogo1 {
  align-self: stretch;
  overflow: hidden;
}
.atc {
  width: 284px;
  filter: var(--shadow-lg);
  padding: var(--padding-3xs) 0;
  box-sizing: border-box;
  min-width: 200px;
}
.partnerLogo2,
.partnerLogoChild {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
}
.partnerLogoChild {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.partnerLogo2 {
  flex-direction: column;
}
.partnerLogo2,
.servicepartners,
.sfs {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sfs {
  /*height: 60px;*/
  flex: 1;
  filter: var(--shadow-lg);
  flex-direction: column;
  padding: var(--padding-10xs) 0;
  box-sizing: border-box;
  min-width: 200px;
}
.servicepartners {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 var(--padding-78xl);
  gap: 24px 34px;
}
.contrainer,
.featuresectionTrustedpartners {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.contrainer {
  width: 100%;
  gap: var(--gap-13xl);
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-content-contentmaxwidth);
}
.featuresectionTrustedpartners {
  align-self: stretch;
  background-color: var(--schemes-on-primary);
  padding: var(--padding-21xl) var(--padding-61xl) var(--padding-77xl);
  box-sizing: border-box;
  min-height: 280px;
  max-width: 100%;
  text-align: left;
  font-size: var(--a-display-medium-size);
  color: var(--dark-gray);
  font-family: var(--a-display-medium);
}
@media screen and (max-width: 1275px) {
  .servicepartners {
    padding-left: var(--padding-29xl);
    padding-right: var(--padding-29xl);
    box-sizing: border-box;
  }
  .contrainer {
    max-width: var(--frame-sizes-content-contentmaxwidth);
  }
}
@media screen and (max-width: 750px) {
  .trustedPartners {
    font-size: var(--font-size-15xl);
    line-height: 42px;
  }
  .servicepartners {
    gap: var(--gap-mid);
    padding-left: var(--padding-5xl);
    padding-right: var(--padding-5xl);
    box-sizing: border-box;
  }
  .contrainer {
    gap: var(--gap-base);
  }
  .featuresectionTrustedpartners {
    gap: var(--gap-xl);
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .trustedPartners {
    font-size: var(--font-size-6xl);
    line-height: 31px;
  }
}
