*:focus {
    outline: 0 !important;
}

.Site .lander {
  padding: 0px 16px 0px 16px;
  text-align: left;

}
.Site .lander2 {
  padding: 0px 0px 0px 0px;
  text-align: left;

}
/*
.Site .lander h1 {
  font-family: "Roboto", Roboto;
  font-weight: 700;
}
*/
.Site .lander h4 {
  font-family: 'Comfortaa', cursive;
  color: #10385A;
}
.Site .lander2 h4 {
  font-family: 'Comfortaa', cursive;
  color: #10385A;
}
.Site .lander h5 {
  font-family: 'Comfortaa', cursive;
  color: #10385A;
}
.Site .lander2 h5 {
  font-family: 'Comfortaa', cursive;
  color: #10385A;
}

*:focus {
    outline: none;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
                                }

#grad1 {
  background-color: rgba(250,250,250,0.4); /* For browsers that do not support gradients */
  background-image: linear-gradient(rgba(250,250,250,0), rgba(250,250,250,0.9), rgba(250,250,250,1));
}

#aaa.container  {
  width: 100%;
  height: 100%;
  z-index: -10;
  text-align: center;
  transition: all 1500ms ease;
}
