/*@import '../variables.css';*/

.date-selection {
  padding: 20px;
  background-color: var(--background-color);
}

.calendar-view-btn {
  /*  position: absolute;*/
  top: 20px;
  right: 20px;
  padding: 10px 15px;
  background-color: var(--secondary-accent-color);
  color: var(--primary-brand-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--body-font);
}

.date-carousel {
  display: flex;
  overflow-x: auto;
  padding: 20px 0;
}

.date-card {
  /*flex: 0 0 auto;*/
  min-width: 110px;
  min-height: 185px;
  width: 110px;
  height: 185px;
  margin-right: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
 
}

.date-card.selected {
    border-style: solid;
    border-width: 3px;
    border-color: var(--primary-brand-color);
  background-color: #d1f4fa;
  /*color: white;*/
  
}

.date-card.unavailable {
    background-color: #f4f4f4;
    color: #333333;
  }

.day {
  font-size: 12px;
  /*font-weight: bold;*/
}

.pitches {
    font-size: 12px;
    margin: -10px 0 20px;
  }

.date {
  font-size: 22px;
  font-weight: bold;
  margin: 5px 0;
}

.month {
  font-size: 14px;
  font-weight: bold;
  margin: -10px 0 20px;
}

.summary {
  margin-top: 20px;
  text-align: center;
}

.select-dates-btn {
  padding: 15px 30px;
  background-color: var(--primary-brand-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: bold;
  /*transition: background-color 0.3s ease;*/
  width: 100%;
}

.select-dates-btn:hover {
    background-color: #0c2d4a;
}



.features-card {
    flex: 0 0 auto;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
    padding-top: 2px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    
   
  }

  .features-card.darker {
     background-color: rgb(249, 249, 249);
     box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
     margin-bottom: 4px;
   
  }

  

@media (max-width: 768px) {
  .date-card {
    width: 70px;
    height: 90px;
  }
}


/* FILTER BAR styling ----------------------------------------------------------------------------------------------------------------------------------------------------------------*/
.filter-container {
  display: flex;
  gap: 1rem;  /* Reduced from 2rem for better spacing on small screens */
  padding: 0.5rem;  /* Reduced padding for small screens */
  align-items: center;
  flex-wrap: nowrap;  /* Prevent wrapping */
  background-color: #fff;
  overflow-x: auto;  /* Allow horizontal scrolling if needed */
  -webkit-overflow-scrolling: touch;  /* Smooth scrolling on iOS */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
  -ms-overflow-style: none;  /* Hide scrollbar for IE/Edge */
  border-radius: 10px;
}

/* Hide scrollbar for Chrome/Safari */
.filter-container::-webkit-scrollbar {
  display: none;
}

/* Container for dropdown and its label */
.select-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #10385A;
  min-width: 90px;  /* Reduced minimum width */
  flex-shrink: 0;  /* Prevent shrinking */
}

/* Dropdown select width */
.custom-select {
  min-width: 90px;  /* Reduced minimum width */
  height: 40px;
  background: #fff;
}

/* Dropdown text styling */
.custom-select .MuiSelect-select {
  color: #10385A;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  text-align: center;
  padding: 0 4px !important;  /* Reduced padding */
  font-size: 1rem;  /* Slightly smaller font */
}

/* Dropdown border styling */
/*.custom-select .MuiOutlinedInput-notchedOutline {
  border: none;
}*/

/* Dropdown hover state border */
/*.custom-select:hover .MuiOutlinedInput-notchedOutline {
  border-color: #10385A;
}

/* Dropdown focus state border */
/*.custom-select.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #10385A;
}

/* "Vehicle length" label styling */
.select-label {
  color: #10385A;
  /*font-family: 'Montserrat', sans-serif;*/
  font-size: 0.75rem !important;
  font-weight: 400;
  text-align: center;
  padding-bottom: 0.75rem;
}


.toggle-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #10385A;
  min-width: 50px;  /* Reduced minimum width */
  flex-shrink: 0;  /* Prevent shrinking */
}





/* Toggle button base styling */
.custom-toggle-button {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;  /* Reduced gap */
  padding: 0.5rem;  /* Reduced padding */
  border: none !important;
  outline: none;
  border-radius: 0px !important;
  background-color: transparent !important;
  color: #ACABAD;
  min-width: 70px;  /* Reduced minimum width */
  
  flex-shrink: 1;  /* Prevent shrinking */
  margin: 0;
}

/* Remove background on hover */
.custom-toggle-button:hover {
  background-color: #10385A;
}

/* Selected toggle button state */
.custom-toggle-button.selected {
 color: #10385A;  /* Dark blue for selected state */
  border-bottom: 2px solid #10385A !important;  /* Underline for selected state */
}

/* Icon sizing in toggle buttons */
.custom-toggle-button .MuiSvgIcon-root {
  width: 24px;  /* Slightly smaller icons */
  height: 24px;
}

/* Text styling in toggle buttons */
.custom-toggle-button .MuiTypography-root {
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;  /* Smaller font size */
  font-weight: 400;
  text-transform: none !important;
  white-space: nowrap;  /* Prevent text wrapping */
}



/* Remove the media query since we're handling small screens differently */
@media (max-width: 600px) {
  .filter-container {
    gap: 0;  /* Remove gap on very small screens */
    justify-content: flex-start;  /* Align items to start */
    padding: 0.25rem;  /* Further reduce padding */
  }
  
  .custom-toggle-button {
    padding: 0.5rem 0.25rem;  /* Reduce horizontal padding */
  }
} 
