/*@import '../variables.css';*/

.daily-trans {
    padding: 20px 0 4px 0;
    background-color: white;
   border-width: 0px 1px 1px 1px;
   
   border-radius: 0 6px 6px 6px ;
   border-bottom-color: #0c2d4a;
   border-style: solid;
  }
  /*
  .calendar-view-btn {
    
    top: 20px;
    right: 20px;
    padding: 10px 15px;
    background-color: var(--secondary-accent-color);
    color: var(--primary-brand-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: var(--body-font);
  }
    */
  
  .dailytrans-carousel {
    display: flex;
    overflow-x: auto;
    padding: 3px 0;
    
  }
  
  .dailytrans-card {
    width: 180px;
    height: auto;
    margin-right: 10px;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fafafa;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    
    cursor: pointer;
    text-align: center;
    align-items: center;

    flex: 0 0 auto;
  /*width: 110px;
  height: 185px;
  margin-right: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  */

  }

  
  
  .dailytrans-card.selected {
      border-style: solid;
      border-width: 3px;
      border-color: var(--primary-brand-color);
    background-color: #bdbdbd;
    /*color: white;*/
    
  }
  
  .dailytrans-card.unavailable {
      background-color: #f4f4f4;
      color: #bdbdbd;
    }
  
  .dailytrans-day {
    font-size: 14px;
    /*font-weight: bold;*/
  }
  
  .dailytrans-pitches {
      font-size: 10px;
      margin: -15px 0 20px;
    }
  
  .dailytrans-date {
    font-size: 22px;
    font-weight: bold;
    margin: 5px 0;
  }
  
  .dailytrans-month {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 20px;
  }
  
  .dailytrans-summary {
    margin-top: 20px;
    text-align: center;
  }
  /*
  .select-dates-btn {
    padding: 15px 30px;
    background-color: var(--primary-brand-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: var(--body-font);
    font-size: 16px;
    font-weight: bold;
    
    width: 100%;
  }
  
  .select-dates-btn:hover {
      background-color: #0c2d4a;
  }
  */


  .pitch-card {
    display:flex;
    flex-flow: column;
    width: 100%;
    height: auto;
    margin-bottom: 8px;
     padding-left: 4px;
    background-color:  var(--primary-brand-color);
    color: white;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    text-align: left;
    align-items: left;
    font-size: 10px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently*/
                  
  }

  .pitch-card p {
    margin: 0 !important;
  }
  .pitch-card.dragover {
    background-color:  red;
  }

  .booking-card {
    width: auto;
    height: auto;
    flex: 1 1 auto;
   margin:0px;
     padding: 4px;
    background-color:   #D6E9F6;
    border-radius: 5px 0px 5px 0px;
    font-size: 14px;
    color: var(--primary-brand-color);
    cursor: pointer;
    text-align: center;
    align-items: center;
   
  }
  .booking-card.previous{
    background-color:  #7e7e7e;
    color: #ffffff;
  }
  .booking-card.previous-nullbay{
    background-color:  #7e7e7e;
    color: #ffffff;
    border-radius: 0px 5px 5px 0px;
  }

  .booking-card.empty{
    background-color:  white;
    color: var(--primary-brand-color);
  }

  .booking-card.empty-nullbay{
    background-color:  white;
    color: var(--primary-brand-color);
    border-radius: 0px 5px 5px 0px;
  }


  .detail-card {
    display:flex;
    width: 100%;
    height: auto;
    margin-bottom: 8px;
     padding: 0px;
    background-color:  #D6E9F6;
    color: var(--primary-brand-color);
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    align-items: center;
  }

  .detail-card.xxx{
    width: auto;
    height: 100%;
    margin-bottom: 0px;
    flex: 1 1 auto;
    font-size: 10px;
  }


  .detail-card.previous{
    background-color:  #7e7e7e;
    color: #ffffff;
  }
  .detail-card.empty{
    background-color:  white;
    color: var(--primary-brand-color);
  }

  .detail-card.facilitiesuse{
    background-color:  #d4d4d4;
    color:  var(--primary-brand-color);
    margin-bottom: 8px;
  }

  .detail-inner {
    width: 30px;
    height: 100%;
   margin:0px;
     padding: 4px;
    background-color:  var(--primary-brand-color);
    border-radius: 5px 0px 0px 5px;
    color: white;
    cursor: pointer;
    text-align: center;
    align-items: center;
    
  }

  .detail-inner.facilitiesuse {
      padding: 10px;
  }

  @media (max-width: 768px) {
    .date-card {
      width: 70px;
      height: 90px;
    }
  }
  
  
  
  .pitch-number-booking {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* for horizontal aligning of child divs */
    justify-content: fill;
    /* for vertical aligning */
    align-items: center;
  }  

  .pitch-number {
    width: 30px;
    height: 100%;
   margin:0px;
    
     padding: 0px;
    background-color:  var(--primary-brand-color);
    border-radius: 0px 0px 0px 5px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    text-align: center;
    align-items: center;
   
  }
