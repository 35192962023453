.userCircle {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: 50%;
  background-color: var(--a-blue-0);
}
.undrawMaleAvatarG98d1Icon {
  height: 120px;
  width: 120px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.graphicGroup,
.usericons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.usericons {
  flex: 1;
  z-index: 1;
  margin-left: -122px;
}
.graphicGroup {
  align-self: stretch;
  height: 120px;
  padding: 0 var(--padding-51xl);
  box-sizing: border-box;
}
.theBigOxmox {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.vectorIcon,
.vectorIcon1,
.vectorIcon2,
.vectorIcon3,
.vectorIcon4 {
  height: 19px;
  width: 20px;
  position: relative;
  min-height: 19px;
}
.vectorParent {
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.name {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.15px;
  line-height: 24px;
}
.guestHost {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 16px;
  display: inline-block;
  min-width: 30px;
}
.userType {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  text-align: left;
  font-size: var(--a-body-small-size);
  color: var(--gray);
}
.cardtestimonialCard,
.nameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.nameParent {
  align-self: stretch;
  border-top: 1px solid var(--light-border);
  padding: var(--padding-xs) 0 0;
  color: var(--color-black);
}
.cardtestimonialCard {
  flex: 1;
  box-shadow: var(--shadow-md);
  border-radius: var(--px-sizes-2xs);
  background-color: var(--schemes-on-primary);
  padding: var(--padding-5xl) var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-5xl);
  min-width: var(--frame-sizes-card-smmin);
  max-width: var(--frame-sizes-card-smmax);
  text-align: center;
  font-size: var(--a-title-medium-size);
  color: var(--gray);
  font-family: var(--a-label-large);
}
@media screen and (max-width: 1100px) {
  .cardtestimonialCard {
    max-width: var(--frame-sizes-card-smmax);
  }
}
@media screen and (max-width: 450px) {
  .graphicGroup {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .cardtestimonialCard {
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
    max-width: var(--frame-sizes-card-smmax);
  }
}
