.howItWorks,
.parkWithEase {
  align-self: stretch;
  position: relative;
}
.howItWorks {
  margin: 0;
  font-size: inherit;
  line-height: 52px;
  font-weight: 700;
  font-family: inherit;
}
.parkWithEase {
  font-size: var(--a-title-medium-size);
  letter-spacing: 0.15px;
  line-height: 24px;
  font-family: var(--a-label-large);
}
.titletext {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.featureIcons {
  width: 80px;
  height: 80px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.locateAnAire {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.25px;
  line-height: 20px;
}
.card {
  flex: 1;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-9xs) var(--px-sizes-xs);
  box-sizing: border-box;
  gap: var(--px-sizes-xs);
  min-width: 256px;
  max-width: 448px;
}
.featureIcons1 {
  width: 80px;
  height: 80px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.locateAnAire1 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.25px;
  line-height: 20px;
}
.card1 {
  flex: 1;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-9xs) var(--px-sizes-xs);
  box-sizing: border-box;
  gap: var(--px-sizes-xs);
  min-width: 256px;
  max-width: 448px;
}
.featureIcons2 {
  width: 80px;
  height: 80px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.locateAnAire2 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.25px;
  line-height: 20px;
}
.card2 {
  flex: 1;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-9xs) var(--px-sizes-xs);
  box-sizing: border-box;
  gap: var(--px-sizes-xs);
  min-width: 256px;
  max-width: 448px;
}
.featureIcons3 {
  width: 80px;
  height: 80px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.locateAnAire3 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.25px;
  line-height: 20px;
}
.card3 {
  flex: 1;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-9xs) var(--px-sizes-xs);
  box-sizing: border-box;
  gap: var(--px-sizes-xs);
  min-width: 256px;
  max-width: 448px;
}
.cardwrap {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 23px 30.7px;
  font-size: var(--a-label-large-size);
  color: var(--gray);
  font-family: var(--a-label-large);
}
.content,
.featuresectionHowitworks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.content {
  width: 100%;
  gap: var(--gap-21xl);
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-content-contentmaxwidth);
}
.featuresectionHowitworks {
  align-self: stretch;
  background-color: var(--schemes-on-primary);
  padding: var(--px-sizes-m) var(--padding-61xl);
  text-align: center;
  font-size: var(--a-display-medium-size);
  color: var(--dark-gray);
  font-family: var(--a-display-medium);
}
@media screen and (max-width: 1275px) {
  .card,
  .card1,
  .card2,
  .card3 {
    max-width: 100%;
  }
  .content {
    max-width: var(--frame-sizes-content-contentmaxwidth);
  }
}
@media screen and (max-width: 750px) {
  .howItWorks {
    font-size: var(--font-size-15xl);
    line-height: 42px;
  }
  .cardwrap {
    gap: var(--gap-mini);
  }
  .content {
    gap: var(--gap-xl);
  }
  .featuresectionHowitworks {
    gap: var(--frame-sizes-content-contentmargin);
    padding-left: var(--px-sizes-m);
    padding-right: var(--px-sizes-m);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .howItWorks {
    font-size: var(--font-size-6xl);
    line-height: 31px;
  }
  .card,
  .card1,
  .card2,
  .card3 {
    max-width: 100%;
  }
  .featuresectionHowitworks {
    padding-top: var(--px-sizes-m);
    padding-bottom: var(--px-sizes-m);
    box-sizing: border-box;
  }
}
