.undrawSharedGoalsReJvqd1Icon {
  width: 300px;
  flex: 1;
  position: relative;
  max-height: 100%;
  overflow: hidden;
}
.graphicwrapper,
.illustrations {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.illustrations {
  align-self: stretch;
  overflow: hidden;
  justify-content: flex-start;
  padding: 0 var(--padding-xl);
}
.graphicwrapper {
  height: 225.2px;
  justify-content: center;
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-card-mdmax);
}
.zermattCardTitle {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 40px;
  font-weight: 700;
  font-family: inherit;
}
.blankLine,
.wereCommittedTo {
  margin: 0;
  letter-spacing: 0.5px;
  line-height: 26px;
}
.design {
  margin: 0;
  font-size: var(--font-size-3xl);
  line-height: 28px;
  font-weight: 600;
}
.blankLine1,
.weOfferExpert {
  margin: 0;
  letter-spacing: 0.5px;
  line-height: 26px;
}
.construction {
  margin: 0;
  font-size: var(--font-size-3xl);
  line-height: 28px;
  font-weight: 600;
}
.blankLine2,
.weProvideRecommendations {
  margin: 0;
  letter-spacing: 0.5px;
  line-height: 26px;
}
.opening {
  margin: 0;
  font-size: var(--font-size-3xl);
  line-height: 28px;
  font-weight: 600;
}
.blankLine3,
.weHelpYou {
  margin: 0;
  letter-spacing: 0.5px;
  line-height: 26px;
}
.businessAsUsual {
  margin: 0;
  font-size: var(--font-size-3xl);
  line-height: 28px;
  font-weight: 600;
}
.blankLine4,
.ourUserFriendlyPlatform,
.withAirestopYoure {
  margin: 0;
  letter-spacing: 0.5px;
  line-height: 26px;
}
.zermattCardDescriptionContainer {
  align-self: stretch;
  position: relative;
  color: var(--color-slategray-100);
  display: inline-block;
  min-height: 554px;
  font-size: var(--a-body-xlarge-size);
  font-family: var(--a-label-large);
}
.textWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.ctaChild {
  width: 302.7px;
  height: 0.7px;
  border-top: 0.7px solid var(--studio-lightmode-lightseparator-ecf0f6);
  box-sizing: border-box;
  flex-shrink: 0;
}
.button,
.ctaChild,
.icon {
  position: relative;
}
.button {
  height: 22px;
  letter-spacing: 0.01em;
  line-height: 22px;
  font-weight: 500;
  display: inline-block;
}
.icon {
  height: 18px;
  width: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.locobutton {
  border-radius: var(--br-7xs);
  border: 1px solid var(--a-others-key-colors-primary);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-8xl);
  gap: var(--gap-8xs);
  white-space: nowrap;
}
.locobutton:hover {
  background-color: var(--color-gainsboro-100);
}
.cta {
  align-self: stretch;
  height: 50px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: center;
  font-size: var(--a-title-medium-size);
  font-family: var(--a-label-large);
}
.contentwarp,
.infomation {
  display: flex;
  justify-content: center;
}
.infomation {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-13xl);
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-card-mdmax);
}
.contentwarp {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 40px 19px;
  max-width: 100%;
}
.container,
.contentcomponentHowairestopsu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.container {
  width: 100%;
  align-items: flex-start;
  min-width: var(--frame-sizes-content-contentminwith);
  max-width: var(--frame-sizes-content-contentmaxwidth);
}
.contentcomponentHowairestopsu {
  align-self: stretch;
  box-shadow: 0 0 52.6px rgba(0, 0, 0, 0.03);
  background-color: var(--schemes-on-primary);
  overflow: hidden;
  align-items: center;
  padding: var(--padding-45xl) var(--padding-61xl);
  box-sizing: border-box;
  min-width: 360px;
  max-width: 100%;
  text-align: left;
  font-size: var(--a-headline-large-size);
  color: var(--a-others-key-colors-primary);
  font-family: var(--a-display-medium);
}
@media screen and (max-width: 1275px) {
  .container {
    max-width: var(--frame-sizes-content-contentmaxwidth);
  }
}
@media screen and (max-width: 750px) {
  .graphicwrapper {
    gap: var(--gap-base);
    max-width: var(--frame-sizes-card-mdmax);
  }
  .zermattCardTitle {
    font-size: var(--font-size-7xl);
    line-height: 32px;
  }
  .infomation {
    gap: var(--gap-base);
    max-width: var(--frame-sizes-card-mdmax);
  }
  .container {
    gap: var(--gap-xl);
  }
  .contentcomponentHowairestopsu {
    gap: var(--gap-4xl);
    padding: var(--padding-23xl) var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .zermattCardTitle {
    font-size: var(--font-size-lgi);
    line-height: 24px;
  }
}
